.sidetab_overlay {
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  z-index: 999;
  flex-direction: row;
  position: absolute;
  position: sticky;
  width: $sidebar_width;
  background: rgba($color: var(--black), $alpha: 0.25);
  .overlay {
    flex: 1;
    height: 100%;
  }
  @include medium {
    width: 100vw;
    position: absolute;
    &[data-open-tab="true"] {
      display: flex;
    }
    &[data-open-tab="false"] {
      display: none;
    }
  }
}

.sidetab_container {
  width: $sidebar_width;
  height: 100%;
  background: var(--alabaster);
  padding-top: 40px;
  align-items: center;
  flex-direction: column;

  div,
  button {
    width: max-content;
    height: max-content;
  }

  .dark-mode-switch {
    width: 38px;
    height: 20px;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 30px;
    flex-direction: row;
    align-items: center;
    background: var(--silver);
    &__toggle {
      left: 3.5px;
      width: 13px;
      height: 13px;
      position: absolute;
      background: #fff;
      border-radius: 15px;
      transition: left 0.3s;
    }
    &[data-move="true"] {
      background: var(--orient);
      .dark-mode-switch__toggle {
        left: calc(50% + 2.5px);
      }
    }
  }

  .app_logo {
    width: 100%;
    display: flex;
    align-items: center;
    width: calc(100% - (44px * 2));
  }

  .connect_wallet {
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 10px;
    background: var(--orient);
    flex-direction: row;
    color: var(--white);
    padding: 14px 20px;
    margin-top: 60px;
    i {
      margin-left: 8px;
      margin-bottom: -1px;
    }
    img {
      width: 24px;
      margin-right: 10px;
    }
  }

  .nav_link_theme {
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    .nav_links {
      width: 100%;
      display: flex;
      margin-top: 40px;
      align-items: center;
      flex-direction: column;
      a {
        width: 100%;
        gap: 10px;
        display: flex;
        color: var(--dove-gray);
        padding: 0px 44px;
        align-items: center;
        flex-direction: row;
        margin-bottom: 14px;
        cursor: pointer;

        svg {
          width: 22px;
          stroke: var(--dove-gray);
        }
        p {
          flex: 1;
          font-size: 16px;
          @include hdLineHeight();
          padding: 16px 0px;
        }

        &.isActive {
          color: var(--orient-white);
          background: var(--white-shaft);
          svg {
            stroke: var(--orient-white);
          }
          p {
            font-family: $sansmed;
          }
        }
      }
    }
    .hor_line_cont {
      width: 100%;
      margin-bottom: 14px;
      .hor_line {
        width: 100%;
        border-bottom: 1px solid var(--alto);
      }
    }

    .theme_block {
      width: 100%;
      flex-direction: column;
      padding: 0px 0px 30px;
      .theme_config {
        gap: 14px;
        align-items: center;
        padding: 0px 44px;
        flex-direction: row;
        svg {
          width: 35px;
        }
      }
    }
  }
}
