.setup_content {
  gap: 32px;
  width: 500px;
  max-width: 100%;
  flex-direction: column;

  .setup_input {
    gap: 10px;
    width: 100%;
    flex-direction: row;
    .assets {
      width: 140px;
      height: 54px;
      padding: 0px 10px;
      align-items: center;
      flex-direction: row;
      border-radius: 10px;
      border: 1px solid var(--mercury);
      justify-content: space-between;
      .icon_name {
        gap: 10px;
        align-items: center;
        flex-direction: row;
        svg {
          width: 24px;
        }
      }
      .dropdown_icon {
        font-size: 14px;
      }
    }

    .amount_input {
      flex: 1;
      height: 54px;
      padding: 0px 10px;
      border-radius: 10px;
      border: 1px solid var(--mercury);
    }
  }

  .warning_component {
    gap: 10px;
    padding: 18px 15px;
    border-radius: 5px;
    flex-direction: row;
    color: var(--jaffa-tussock);
    background: var(--serenade-bistre);

    .warn_icon {
      font-size: 24px;
      margin-top: 2px;
    }
  }

  .continue_button {
    display: flex;
    border-radius: 10px;
    background: var(--orient);
    flex-direction: row;
    color: var(--white);
    padding: 16px 50px;
    cursor: pointer;
  }
}
