nav {
  top: 0;
  width: 100%;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  @include padHor;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid var(--alto);

  height: $nav-height;
  @include phone {
    height: $nav-height-sm;
  }

  .nav-btn {
    gap: 15px;
    cursor: pointer;
    align-items: center;
    flex-direction: row;
    p {
      font-size: $font-rg;
      font-family: $sansmed;
      &.capitalize {
        text-transform: capitalize;
      }
    }
    svg {
      stroke: var(--timber-white);
    }
  }

  .action-btns {
    gap: 5px;

    align-items: center;
    flex-direction: row;

    .network-menu {
      width: 100%;
      min-width: 50px;
      color: var(--timber-altra);
      background: var(--bg-color);
      overflow: hidden;
      border-radius: 6px;
      padding: 0px 0px !important;
      border: 1px solid var(--none-alto);
      .divider {
        width: 100%;
        height: 1px;
        background: var(--alto);
      }
      .menu-item {
        padding: 10px 10px;
        align-items: center;
        justify-content: center;
        p {
          font-size: 15px;
          text-align: center;
          text-transform: uppercase;
          &[data-active="true"] {
            color: var(--orient-caribbean);
            font-family: $sansmed;
          }
        }
        &:hover {
          background: var(--gallery-alto);
        }
      }
    }
    .network-switch {
      position: relative;
      flex-direction: column;
      .network-switch-btn {
        gap: 6px;
        display: flex;
        cursor: pointer;
        color: var(--orient-snowy);
        padding: 2.5px 10px;
        align-items: center;
        border-radius: 6px;
        justify-content: center;
        text-transform: capitalize;
        text-transform: uppercase;
        // font-family: $sansmed;
        border: 1px solid var(--orient-snowy);
        p {
          font-size: 14px;
        }
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 14px;
          }
        }
        svg {
          width: 13px;
          stroke: var(--orient-snowy);
        }
      }
    }

    img {
      width: 46px;
      height: 46px;
      border-radius: 100%;
    }
    .notif_icon {
      cursor: pointer;
      padding: 5px 10px;
    }

    @include phone {
      img {
        width: 38px;
        height: 38px;
      }
      .profile-options {
        display: none;
      }
    }
  }
}

.empty_state {
  width: 100%;
  padding-top: 8vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include phone {
    height: 100%;
    padding-top: 0px;
    padding-bottom: 20%;
  }

  .illustration_cover {
    svg {
      width: 226px;
      margin-bottom: 35px;
      &.small_size {
        width: 280px;
        margin-bottom: 25px;
      }
      &.large_margin {
        margin-bottom: 40px;
      }
      &.small_margin {
        margin-bottom: 20px;
      }
    }
  }

  .text {
    color: var(--dove-gray);
    display: block;
    font-size: 17px;
    max-width: 443px;
    text-align: center;
    font-family: $sansmed;
    a {
      color: var(--orient-caribbean);
      font-family: $sansmed;
    }

    &.bold_text {
      color: var(--timber-green);
      font-size: $font-bg;
      font-family: $sansbold;
      text-transform: uppercase;
    }
  }

  @include phone {
    .illustration_cover {
      svg {
        width: 170px;
        height: 170px;
        margin-bottom: 15px;
        &.small_size {
          width: 220px;
          margin-bottom: 15px;
        }
        &.small_margin {
          margin-bottom: 15px;
        }
      }
    }
    .text {
      &.bold_text {
        font-size: $font-md;
      }
    }
  }

  button {
    display: flex;
    border-radius: 10px;
    background: var(--orient);
    flex-direction: row;
    color: var(--white);
    padding: 16px 20px;
    gap: 10px;
    cursor: pointer;
    margin-top: 16px;
    img {
      width: 24px;
    }
  }
}

.top_bar {
  width: 100%;
  padding-top: 45px;
  padding-bottom: 35px;
  @include padHor;
  flex-direction: column;
  @include medium {
    padding-top: 25px;
    padding-bottom: 10px;
  }

  &.payment-dets {
    min-height: 150px;
  }

  .main {
    gap: 35px;
    flex-direction: row;
    justify-content: space-between;
    .bar_title {
      font-size: $font-md;
      font-family: $sansbold;
    }

    .payment_details_header {
      flex: 1;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      .link_name {
        span {
          color: var(--timber-green);
          font-family: $sansmed;
        }
      }
      .set_link {
        gap: 10px;
        color: var(--timber-green);
        align-items: center;
        flex-direction: row;
        p {
          font-family: $sansmed;
        }

        .switch-btn {
          width: 38px;
          height: 20px;
          display: flex;
          cursor: pointer;
          position: relative;
          border-radius: 30px;
          flex-direction: row;
          align-items: center;
          background: var(--silver);
          &__toggle {
            left: 3.5px;
            width: 13px;
            height: 13px;
            position: absolute;
            background: #fff;
            border-radius: 15px;
            transition: left 0.3s;
          }
          &[data-move="true"] {
            background: var(--orient);
            .switch-btn__toggle {
              left: calc(50% + 2.5px);
            }
          }
        }
      }
    }

    .bar_buttons {
      gap: 16px;
      .copy_button,
      .filter_button,
      .generate_link {
        gap: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: var(--orient);
        padding: 14px 40px;
        border-radius: 10px;
        border-radius: 10px;
        flex-direction: row;
        background: var(--orient);
        background: var(--ice-cold);
        p {
          font-size: $font-sm;
          font-family: $sansmed;
        }
      }

      .generate_link {
        color: var(--white);
        padding: 14px 30px;
        background: var(--orient);
      }
    }
  }

  .sub {
    color: var(--dove-gray);
    margin-top: 9px;
  }

  .payment_details_bar {
    width: 100%;
    gap: 20px 30px;
    flex-wrap: wrap;
    margin-top: 5px;
    flex-direction: row;

    .row_member {
      flex: 1;
      height: 88px;
      min-width: 160px;
      max-width: max-content;
      border-radius: 3px;
      padding: 16px 20px;
      white-space: nowrap;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid var(--alto);
      @include tablet {
        flex: 1;
        max-width: none;
      }

      &.name {
        max-width: 250px;
      }
      &.amt,
      &.rev,
      &.interval {
        align-items: center;
        .main_text,
        .sub_text {
          text-align: center;
        }
      }
      &.asset {
        .main_text {
          padding-left: 2px;
        }
      }
      &.rev {
        max-width: 200px;
      }

      .main_text {
        opacity: 0.8;
        font-size: 14px;
        margin-bottom: 5px;
        text-transform: uppercase;
      }
      .sub_text {
        width: 100%;
        color: var(--black-white);
        overflow: hidden;
        font-family: $sansmed;
        text-overflow: ellipsis;
      }

      .asset_cont {
        gap: 8px;
        align-items: center;
        flex-direction: row;
        p {
          opacity: 0.8;
          font-size: 14px;
          font-family: $sansmed;
        }
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
          border-radius: 100%;
        }
      }
      .status_block {
        width: max-content;
        align-items: center;
        padding: 1px 10px;
        border-radius: 30px;
        font-size: 12px;
        justify-content: center;

        &.continual {
          color: var(--orient-jordy);
          background: var(--onahau-rhino);
        }
        &.onetime {
          color: var(--blue-periwinkle);
          background: var(--periwinkle-cloud);
        }
      }
    }

    @include tablet {
      gap: 15px 20px;
    }
  }

  @include medium {
    .main {
      .bar_title {
        width: 0px;
        height: 0px;
        overflow: hidden;
      }
      .bar_buttons {
        .filter_button {
          padding: 14px 10px;
          background: transparent;
        }
        .copy_button {
          padding: 14px 20px;
        }
        .generate_link {
          padding: 14px 20px;
        }
      }
    }
  }
}

.hor_line_cont {
  width: 100%;
  .hor_line {
    width: 100%;
    border-bottom: 1px solid var(--alto);
  }
}

.drop_down_cover {
  display: flex;
  position: relative;
  flex-direction: column;

  &.right_aligned {
    align-items: flex-end;
  }

  .drop_down_list {
    width: 100%;
    z-index: 9999;
    padding: 20px;
    min-width: max-content;
    top: calc(100% + 5px);
    height: max-content;
    border-radius: 5px;
    position: absolute;
    background: var(--bg-color);
    flex-direction: column;
    overflow: hidden scroll;
    border: 1px solid var(--alto);

    &.solid-border {
      background: var(--alabaster);
      padding: 10px 20px !important;
      // border: 1.2px solid var(--silver) !important;
    }

    .content {
      gap: 10px;
      width: 100%;
      color: var(--dove-gray);
      flex-direction: column;
      .header {
        color: var(--caribbean-green);
      }
      .content_list {
        gap: 18px;
        width: 100%;
        margin-top: 5px;
        flex-direction: column;
      }
      .list_item {
        gap: 10px;
        cursor: pointer;
        img {
          width: 25px;
          height: 25px;
          border-radius: 100%;
          object-fit: cover;
        }
        &[data-active="true"] {
          color: var(--orient-caribbean);
          font-family: $sansmed;
        }
        &.current,
        &.current p {
          color: var(--orient-caribbean);
          font-family: $sansmed;
        }
      }
    }
  }

  .singleOption {
    gap: 8px;
    width: 100%;
    align-items: center;
    justify-content: center;
    p {
      font-family: $sansmed;
      color: var(--orient-white);
    }
  }

  &.full_width {
    width: 100%;
    .drop_down_list {
      padding: 15px 20px;
      border-radius: 10px;
      border: 1px solid var(--alto);
      .content {
        gap: 15px;
      }
    }
  }
  &.notification {
    .drop_down_list {
      padding: 24px 0px;
      .notification_container {
        gap: 20px;
        width: 365px;
        flex-direction: column;
        .header {
          width: 100%;
          padding: 0px 15px;
          align-items: center;
          flex-direction: row;
          font-family: $sansmed;
          justify-content: space-between;

          .mark_setting {
            gap: 10px;
            align-items: center;
            flex-direction: row;
            p {
              color: var(--caribbean-green);
              font-size: $font-sm;
            }
            svg {
              width: 16px;
              height: 16px;
              stroke: var(--silver);
            }
          }
        }
        .notification_item {
          gap: 15px;
          cursor: pointer;
          padding: 0px 15px;
          flex-direction: row;
          .item_icon {
            width: 40px;
            height: 40px;
            overflow: hidden;
            svg {
              width: 100%;
              height: 100%;
            }
          }
          .item_text {
            flex: 1;
            display: inline;
            a {
              color: var(--orient);
              font-family: $sansmed;
            }
          }
        }

        .empty_notificationn {
          gap: 20px;
          width: 100%;
          align-items: center;
          flex-direction: column;
          .empty_illustration {
            flex-direction: row;
            align-content: center;
            justify-content: center;
            svg {
              width: 86px;
            }
          }
          p {
            font-size: $font-sm;
            color: var(--dove-gray);
            text-align: center;
          }
        }
      }
    }
  }

  &.flex_width {
    width: max-content;
    .drop_down_list {
      padding: 15px 10px;
      border-radius: 10px;
      border: 1px solid var(--alto);
      .content {
        gap: 15px;
        .list_item {
          gap: 8px;
          align-items: center;
          flex-direction: row;
          img {
            width: 25px;
            height: 25px;
            border-radius: 100%;
          }
          svg {
            width: 25px;
          }
          p {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: $font-sl;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
