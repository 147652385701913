.payment_portal_container {
  width: 100%;
  height: 100%;
  flex-direction: row;
  div {
    display: flex;
  }
  .section_block {
    height: 100%;
  }

  .side_decor {
    width: 635px;
    max-width: 50%;
    position: relative;
    background: var(--orient);
    flex-direction: column;
    padding: 92px 0vw 0px 120px;
    .logo_white {
      margin-bottom: 97px;
    }
    .side_decor_text {
      color: var(--white);
      font-size: $font-xl;
      font-family: $sansbold;
    }
    .bg_decor {
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    @include medium {
      display: none;
    }
  }

  .main_container {
    display: flex;
    align-items: center;
    justify-content: center;

    .powered_by_block {
      gap: 20px;
      margin-top: 30px;
      color: var(--orient);
      align-items: center;
      flex-direction: row;
      font-family: $sansbold !important;
      svg {
        height: 26px;
      }
    }

    .continue_to_pay {
      width: 100%;
      display: flex;
      color: var(--white);
      height: 55px;
      cursor: pointer;
      font-family: $sansmed;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: var(--orient);
    }

    .description_container {
      width: 440px;
      max-width: calc(100% - 60px);
      align-items: center;
      flex-direction: column;
      .logo_img {
        width: 70px;
        height: 70px;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--seashell);
        .lazy-loader {
          width: 70px;
          height: 70px;
          margin-top: 4px;
          object-fit: cover;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .payment_illustration {
        width: 256px;
        height: 256px;
        margin-bottom: 40px;
      }

      .description_text {
        gap: 15px;
        margin-bottom: 10px;
        align-items: center;
        flex-direction: column;
        p {
          text-align: center;
          &.main {
            font-family: $sansbold;
            font-size: $font-md;
            color: var(--timber-green);
          }
          &.sub {
            margin-bottom: 15px;
          }
        }
      }

      .input-block {
        gap: 10px;
        width: 100%;
        height: 55px;
        margin-bottom: 25px;
        align-items: center;
        flex-direction: row;
        border-radius: 10px;
        .asset_select {
          gap: 10px;
          flex: none;
          height: 100%;
          padding: 0px 16px;
          width: max-content;
          align-items: center;
          border-radius: 10px;
          border: 1px solid var(--seashell);
          .asset_info {
            gap: 10px;
            align-items: center;
            flex-direction: row;

            p {
              font-size: $font-sl;
              text-transform: uppercase;
            }
            img,
            svg {
              width: 24px;
              height: 24px;
            }
            img {
              border-radius: 100%;
            }
          }
          .caret_down {
            font-size: 14px;
          }
        }

        input {
          flex: 1;
          height: 100%;
          padding: 0px 16px;
          border-radius: 10px;
          border: 1px solid var(--seashell);
        }
      }
    }

    .connect_container {
      width: 100%;
      border-radius: 10px;
      font-size: $font-sm;
      background: var(--white);
      flex-direction: column;

      div {
        display: flex;
        flex-direction: column;
      }
      .option_ticked {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 100%;
        background: var(--gallery);
        svg {
          width: 100%;
          height: 100%;
        }
      }

      .options_header {
        align-items: center;
        margin-bottom: 25px;
        .main {
          margin-bottom: 15px;
          font-size: $font-rg;
          font-family: $sansmed;
        }
        .sub {
          color: var(--dove-gray);
        }
      }
      .connect_option {
        gap: 10px;
        width: 100%;
        height: 68px;
        cursor: pointer;
        padding: 0px 20px;
        align-items: center;
        flex-direction: row;
        border-radius: 10px;
        margin-bottom: 20px;
        border: 1px solid var(--concrete);
        justify-content: space-between;
        .option_info {
          gap: 10px;
          align-items: center;
          flex-direction: row;
          svg {
            width: 32px;
          }
          p {
            flex: 1;
            color: var(--dove-gray);
          }
        }
      }
    }
  }
}
