.settings_nav_tab {
  gap: 50px;
  width: 100%;
  border-bottom: 1px solid var(--alto);

  a {
    font-size: $font-sl;
    width: max-content;
    text-transform: uppercase;
    p {
      padding: 12px 4px;
      white-space: nowrap;
    }
    &.isActive {
      p {
        font-family: $sansmed;
        color: var(--orient-altra);
        border-bottom: 4px solid var(--orient-altra);
      }
    }
  }

  @include medium {
    padding-top: 15px;
  }
  @include phone {
    gap: 30px;
    a {
      p {
        font-size: 14px;
        padding: 12px 0px;
      }
    }
  }
}

.profile_cover {
  flex: 1;
  width: 100%;
  flex-direction: column;
  .user_profile {
    margin-top: 30px;
    flex-direction: column;
    .profile_pic {
      width: 120px;
      height: 120px;
      margin-bottom: 10px;
      border-radius: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
    }
    .user_email {
      width: 340px;
      max-width: 100%;
      padding: 8px 20px;
      border-radius: 10px;
      flex-direction: column;
      border: 1px solid var(--mercury);
      .title {
        color: var(--dove-gray);
      }
      .value {
        color: var(--timber-green);
      }
    }
  }

  .delete_button_block {
    width: 100%;
    margin-top: 50px;
    flex-direction: row;
    justify-content: flex-end;
    .delete_button {
      gap: 10px;
      display: flex;
      cursor: pointer;
      color: var(--white);
      padding: 14px 40px;
      border-radius: 10px;
      border-radius: 10px;
      flex-direction: row;
      font-family: $sansmed;
      background: var(--burnt-sienna);
    }
  }
}
.api_settings,
.webhook-settings {
  gap: 25px;
  width: 100%;
  margin-top: 25px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .api_keys,
  .webhook-block {
    gap: 20px;
    width: 500px;
    max-width: 100vw;
    overflow: hidden;
    flex-direction: column;

    &__input {
      gap: 15px;
      flex-direction: column;
      input {
        width: 100%;
        padding: 18px 20px;
        border-radius: 10px;
        flex-direction: row;
        align-items: center;
        border: 1px solid var(--mercury);
      }
      .warning-text {
        color: var(--jaffa-tussock);
      }
    }

    .api_key,
    .webhook {
      gap: 15px;
      width: 100%;
      padding: 10px 20px;
      border-radius: 10px;
      flex-direction: row;
      align-items: center;
      border: 1px solid var(--mercury);
      &_inner {
        flex: 1;
        height: 100%;
        overflow: hidden;
        flex-direction: column;
        .title {
          width: 100%;
          overflow: hidden;
          color: var(--dove-gray);
          text-overflow: ellipsis;
        }
        .value {
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--timber-altra);
        }
      }
      .copy_key {
        padding: 5px;
        flex: none;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
          stroke: var(--alto);
        }
      }
    }

    .hide_secret_key {
      cursor: pointer;
      padding-left: 10px;
      padding-right: 20px;
      font-family: $sansmed;
      max-width: max-content;
      color: var(--timber-caribbean);
    }

    .save_changes {
      gap: 10px;
      display: flex;
      margin-top: 16px;
      cursor: pointer;
      color: var(--orient);
      padding: 12px 40px;
      border-radius: 10px;
      border-radius: 10px;
      flex-direction: row;
      font-family: $sansmed;
      border: 1px solid var(--orient);
    }
  }

  .generate_new_keys,
  &__set-url {
    gap: 10px;
    display: flex;
    cursor: pointer;
    color: var(--white);
    white-space: nowrap;
    height: max-content;
    padding: 18px 35px;
    border-radius: 10px;
    border-radius: 10px;
    flex-direction: row;
    background: var(--orient);
    &.empty {
      margin-top: 20px;
      padding: 12px 20px;
    }
  }
}

.preferences {
  margin-top: 25px;
  .category {
    gap: 90px;
    flex-direction: row;
    .class {
      color: var(--timber-green);
      font-family: $sansmed;
    }

    .options {
      flex-direction: column;

      .option {
        gap: 15px;
        width: 100%;
        border-radius: 10px;
        flex-direction: row;
        margin-bottom: 25px;
        &_inner {
          flex: 1;
          height: 100%;
          flex-direction: column;
          .title {
            font-family: $sansmed;
            color: var(--timber-green);
            margin-bottom: 5px;
          }
          .value {
            color: var(--dove-gray);
          }
        }
        .check_option {
          padding: 5px;
          cursor: pointer;
          svg {
            margin-top: 1px;
            transform: scale(1.2);
          }
        }
      }
    }

    @include phone {
      gap: 30px;
      flex-direction: column;
    }
  }
}
