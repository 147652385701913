.pagination_container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 25px 0px 20px;
  justify-content: center;

  .pagination {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    border-radius: 8px;
    align-content: center;
    border: 1px solid rgba(100, 100, 100, 0.4);

    background: var(--alabaster);
    &__item {
      height: 36px;
      display: flex;
      opacity: 0.7;
      cursor: pointer;
      padding: 0px 14px;
      align-items: center;
      font-family: $sansmed;
      justify-content: center;
      color: var(--timber-white);
      border-right: 1px solid rgba(100, 100, 100, 0.4);

      &.nav-btn {
        gap: 2px;
        align-items: center;
        padding: 0px 8px;
        color: var(--orient-caribbean);
        flex-direction: row;
        p {
          font-family: $sansmed;
        }
        i {
          margin-bottom: -1px;
        }
        &[data-inactive="true"] {
          color: var(--dove-mercury);
          cursor: not-allowed;
        }
      }

      &[data-active="true"] {
        font-size: 18px;
        color: var(--orient-caribbean);
        font-family: $sansmed;
      }
      &[data-hidden="true"] {
        display: none;
      }
      &:last-child {
        border: none;
      }
    }
  }
}
