.portal_modal_background {
  z-index: 9;
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgba($color: var(--black), $alpha: 0.25);

  .modal_container {
    width: 480px;
    max-width: 90vw;
    border-radius: 10px;
    font-size: $font-sm;
    background: var(--white);
    padding: 30px 28px;
    div {
      display: flex;
      flex-direction: column;
    }

    .modal_content {
      width: 100%;
      align-items: center;
      flex-direction: column;

      .modal_illustration {
        width: 336px;
        height: 336px;
        overflow: hidden;
        margin-bottom: 15px;
      }

      .modal_description {
        gap: 15px;
        max-width: 385px;
        margin-bottom: 24px;
        p {
          text-align: center;
        }
        .main {
          margin-bottom: 5px;
          font-size: $font-md;
          font-family: $sansbold;
        }
        .sub {
          color: var(--dove-gray);
        }
      }
    }

    .action_buttons {
      gap: 20px;
      width: 100%;
      height: 56px;
      margin-top: 16px;
      flex-direction: row;
      button {
        flex: 1;
        gap: 10px;
        height: 100%;
        display: flex;
        color: var(--white);
        cursor: pointer;
        align-items: center;
        border-radius: 10px;
        font-family: $sansmed;
        justify-content: center;
        flex-direction: row;
        svg {
          width: 20px;
        }
        &.cancel_button {
          background: var(--silver);
        }
        &.continue_button {
          background: var(--orient);
        }
      }
    }
  }
}
