.modal_background {
  z-index: 9999;
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba($color: black, $alpha: 0.25);

  .modal_container {
    width: 460px;
    max-width: 90%;
    overflow-y: scroll;
    border-radius: 10px;
    font-size: $font-sm;
    background: var(--alabaster);
    padding: 30px 28px;
    flex-direction: column;
    max-height: calc(100vh - 30px);

    @include phone {
      max-height: 100vh;
    }

    @include scrollbars(10px, transparent, transparent);

    .warning_text {
      gap: 10px;
      padding: 5px 5px;
      color: var(--tango);
      border-radius: 5px;
      flex-direction: row;
      text-transform: uppercase;
      p {
        font-size: 10px;
      }
    }

    &.fill-screen {
      width: 100vw;
      max-width: 100%;
      height: 100vh;
      border-radius: 0px;
      padding-top: 0px;
      padding: 0px 0px 120px;
      @mixin modalPad {
        padding: {
          left: 20px;
          right: 20px;
        }
      }
      .modal_header {
        .main {
          gap: 12px;
          height: 74px;
          @include modalPad;
          margin-bottom: 25px;
          align-items: center;
          flex-direction: row;
          border-bottom: 1px solid var(--alto);
          .back-btn {
            padding: 5px;
            cursor: pointer;
          }
        }
        .sub {
          @include modalPad;
        }
      }
      .modal_content:not(.no-padding) {
        @include modalPad;
      }
      .action_buttons {
        @include modalPad;
      }
    }

    div {
      display: flex;
      flex-direction: column;
    }
    .modal_header {
      margin-bottom: 25px;
      &.no-margin {
        margin-bottom: 0px;
      }
      a {
        color: var(--orient);
        font-family: $sansmed;
      }
      .main {
        margin-bottom: 5px;
        p {
          font-size: $font-rg;
          font-family: $sansmed;
          &.delete {
            color: var(--burnt-sienna);
          }
        }
        .closemodal-x {
          width: 100%;
          flex-direction: row;
          margin-bottom: 6px;
          justify-content: flex-end;
          .back-btn {
            padding: 8px 5px;
            cursor: pointer;
            svg path {
              stroke: var(--timber-altra);
            }
          }
        }
      }
      .sub {
        color: var(--dove-gray);
        .note {
          color: var(--caribbean-green);
        }
      }
    }

    .modal_content {
      width: 100%;

      .option_ticked {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 100%;
        background: var(--gallery-shaft);
        svg {
          width: 100%;
          height: 100%;
        }
      }

      .modal_illustration {
        width: 336px;
        height: 336px;
        margin: 0px auto;
        overflow: hidden;
        margin-bottom: 15px;
      }

      .modal_description {
        gap: 5px;
        width: 100%;
        margin-bottom: 15px;

        p {
          text-align: center;
        }
        .main {
          font-size: $font-rg;
          font-family: $sansmed;
        }
        .sub {
          color: var(--dove-gray);
          display: inline;
        }
        a {
          color: var(--orient);
          font-family: $sansmed;
        }
      }

      //   Connect wallet Modal
      .connect_option {
        gap: 10px;
        width: 100%;
        height: 68px;
        cursor: pointer;
        padding: 0px 20px;
        align-items: center;
        flex-direction: row;
        border-radius: 10px;
        margin-bottom: 20px;
        border: 1px solid var(--alto);
        justify-content: space-between;
        .option_info {
          gap: 10px;
          align-items: center;
          flex-direction: row;
          svg {
            width: 32px;
          }
          p {
            flex: 1;
            color: var(--dove-gray);
          }
        }
      }

      // Generate link Modal
      .logo_block {
        gap: 10px;
        width: 100%;
        margin-bottom: 20px;
        align-items: center;
        flex-direction: column;
        .logo_img {
          width: 70px;
          height: 70px;
          cursor: pointer;
          overflow: hidden;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--alto);
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
        .edit_img {
          color: var(--orient-altra);
          cursor: pointer;
          font-family: $sansmed;
        }
      }
      .generate_link_form {
        gap: 16px;
        color: var(--dove-gray);
        margin-bottom: 20px;
        flex-direction: column;
        .caret_down {
          color: var(--timber-green);
        }
        input {
          height: 60px;
          padding: 0px 20px;
          border-radius: 10px;
          color: var(--timber-alto);
          border: 1px solid var(--alto);
        }

        textarea {
          height: auto;
          flex: 1;
          outline: none;
          resize: none;
          border: none;
          padding: 15px 20px;
          line-height: 1.5;
          overflow: hidden;
          border-radius: 10px;
          color: var(--timber-alto);
          background: transparent;
          border: 1px solid var(--alto);
        }

        .form_row {
          gap: 10px;
          align-items: center;
          flex-direction: row;
          &.contain .flex_width {
            width: 100%;
            .asset_select {
              padding: 15px 20px !important;
              width: 100% !important;
            }
            .drop_down_list {
              padding: 15px 20px !important;
            }
          }
          &.amt_ty {
            gap: 20px;
          }
          input {
            flex: 1;
          }
          .amount_type,
          .asset_select,
          .payment_duration,
          .payment_link_preview {
            flex: 1;
            height: 60px;
            cursor: pointer;
            padding: 0px 20px;
            flex-direction: row;
            align-items: center;
            border-radius: 10px;
            border: 1px solid var(--alto);
            justify-content: space-between;
          }
          .payment_link_preview {
            cursor: default;
          }

          .asset_select {
            gap: 10px;
            flex: none;
            padding: 0px 10px;
            width: max-content;
            .asset_info {
              gap: 10px;
              align-items: center;
              flex-direction: row;

              p {
                font-size: $font-sl;
                min-width: 60px;
                text-transform: uppercase;
              }
              img,
              svg {
                width: 24px;
                height: 24px;
              }
              img {
                border-radius: 100%;
              }
            }
            .caret_down {
              font-size: 14px;
            }
          }
          .payment_duration {
            flex: none;
            width: 100%;
            i {
              color: var(--dove-gray);
              font-size: 20px;
            }
          }
        }
        .warning_component {
          gap: 10px;
          padding: 15px;
          color: var(--jaffa-tussock);
          border-radius: 5px;
          flex-direction: row;
          background: var(--serenade-bistre);

          .warn_icon {
            font-size: 24px;
            margin-top: 2px;
          }
        }
      }

      // Delete account Modal
      .optional_email {
        color: var(--dove-gray);
        margin-bottom: 20px;
        flex-direction: column;
        input {
          height: 60px;
          padding: 0px 20px;
          border-radius: 10px;
          border: 1px solid var(--concrete);
        }
      }

      // Notification modal
      .notification_container {
        // gap: 20px;
        width: 100%;
        flex-direction: column;
        .header {
          width: 100%;
          padding: 0px 15px 10px;
          align-items: center;
          flex-direction: row;
          font-family: $sansmed;
          justify-content: space-between;

          .mark_setting {
            gap: 10px;
            align-items: center;
            flex-direction: row;
            p {
              color: var(--caribbean-green);
              font-size: $font-sm;
            }
            svg {
              width: 16px;
              height: 16px;
              cursor: pointer;
              stroke: var(--silver);
            }
          }
        }
        .notification_item {
          gap: 15px;
          cursor: pointer;
          padding: 20px 15px;
          flex-direction: row;
          .item_icon {
            width: 40px;
            height: 40px;
            overflow: hidden;
            svg {
              width: 100%;
              height: 100%;
            }
          }
          .item_text {
            flex: 1;
            display: inline;
            a {
              color: var(--orient);
              font-family: $sansmed;
            }
          }
        }

        .empty_notificationn {
          gap: 40px;
          width: 100%;
          min-height: 65vh;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          .empty_illustration {
            flex-direction: row;
            align-content: center;
            justify-content: center;
            svg {
              width: 190px;
              height: 190px;
            }
          }
          p {
            font-size: $font-sm;
            color: var(--dove-gray);
            text-align: center;
          }
        }
      }
    }

    .action_buttons {
      flex: none;
      gap: 20px;
      width: 100%;
      height: 56px;
      margin-top: 16px;
      flex-direction: row;
      button {
        flex: 1;
        height: 100%;
        display: flex;
        color: var(--white);
        cursor: pointer;
        align-items: center;
        border-radius: 10px;
        font-family: $sansmed;
        justify-content: center;
        &.cancel_button {
          background: var(--silver-shaft);
        }
        &.continue_button {
          background: var(--orient);
          &.copied {
            color: var(--orient);
            background: var(--ice-cold);
          }
        }
        &.delete_button {
          background: var(--burnt-sienna);
        }
      }
      @include phone {
        gap: 15px;
        &:not(.wallet-connect) {
          height: max-content;
          flex-direction: column-reverse;
          button {
            flex: none;
            width: 100%;
            height: 56px;
          }
        }
      }
    }
  }
}
