.transactions_table {
  // flex: 1;
  width: 100%;
  // height: 100%;
  min-height: 60%;
  position: relative;
  overflow: scroll scroll;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  .table_header,
  .table_row {
    flex: none;
    height: 70px;
    min-width: 100%;
    flex-direction: row;
    width: max-content;
    align-items: center;
    justify-content: space-between;

    gap: 20px;

    .row_member {
      height: 100%;
      align-items: center;
      overflow: hidden;
      p {
        font-size: 15px;
      }
    }
    .amt,
    .ref,
    .date,
    .preview {
      width: 90px;
      max-width: 90px;
      overflow: hidden;
      text-align: center;
      justify-content: center;

      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .amt {
      width: 80px;
      max-width: 80px;
    }
    .preview {
      width: 50px;
      display: flex;
      max-width: 50px;
      svg path {
        stroke: var(--orient-caribbean);
      }
    }

    .ref {
      left: 0px;
      width: 145px;
      max-width: 145px;
      position: sticky;
      text-align: left;
      padding-left: 20px;
      padding-right: 5px;
      justify-content: start;
    }

    .asset {
      gap: 8px;
      width: 120px;
      max-width: 120px;
      justify-content: center;
      p {
        font-size: 14px;
      }
      svg {
        width: 24px;
      }
      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    .status {
      width: 90px;
      max-width: 90px;
      text-align: center;
      justify-content: center;
      .status_block {
        padding: 2px 10px;
        border-radius: 30px;
        font-size: $font-sl;
        &.failed {
          background: var(--red-cocoa);
          color: var(--cosmos-mauvelous);
        }
        &.pending {
          color: var(--jaffa-tussock);
          background: var(--derby-bistre);
        }
        &.successful {
          color: var(--japenese-sea);
          background: var(--snowy-celtic);
        }
      }
    }
    .date {
      width: 100px;
      max-width: 100px;
    }
    .walletAddr {
      gap: 10px;
      width: 130px;
      max-width: 130px;
      text-align: center;
      justify-content: center;

      p {
        font-size: 14px;
      }
    }
  }
  .table_header {
    .row_member {
      color: var(--dove-gray);
      font-size: $font-sl;
      text-transform: uppercase;
    }
    .ref {
      background: var(--bg-color);
    }
  }

  .table_row {
    &:nth-child(odd) {
      .ref {
        background: var(--bg-color);
      }
    }
    &:nth-child(even) {
      background: var(--alabaster);
      .ref {
        background: var(--alabaster);
      }
    }
  }

  .filtered_list-empty {
    flex: 1;
    gap: 24px;
    top: 70px;
    left: 0px;
    width: 100%;
    padding: 50px;
    position: sticky;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid var(--alabaster);
    svg {
      width: 90px;
      height: 90px;
    }
    p {
      font-size: 18px;
      text-align: center;
      color: var(--dove-gray);
    }
  }
}
