.header-sm {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  @include padHor;
  p {
    padding: 0px 10px;
    font-size: $font-rg;
    font-family: $sansmed;
  }
}

.payment_table {
  width: 100%;
  overflow-x: scroll;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  .table_header,
  .table_row {
    height: 70px;
    min-width: 100%;
    flex-direction: row;
    width: max-content;
    align-items: center;
    justify-content: space-between;

    gap: 20px;

    .row_member {
      height: 100%;
      display: flex;
      cursor: pointer;
      overflow: hidden;
      align-items: center;
    }
    .name,
    .amt,
    .rev,
    .link {
      width: 90px;
      max-width: 90px;
      overflow: hidden;
      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .name {
      left: 0px;
      width: 150px;
      max-width: 150px;
      padding-left: 20px;
      p {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      @include tablet {
        width: 120px;
        max-width: 120px;
      }
    }
    .amt {
      left: 0px;
      padding-left: 10px;
      position: sticky;
      justify-content: center;
      text-align: center;
    }

    .rev {
      width: 135px;
      max-width: 135px;
      text-align: center;
      justify-content: center;
    }

    .asset {
      gap: 10px;
      width: 120px;
      max-width: 120px;
      justify-content: center;
      svg {
        width: 25px;
      }
      img {
        width: 25px;
        height: 25px;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    .status,
    .interval {
      width: 120px;
      max-width: 120px;
      justify-content: center;
      .status_block {
        padding: 2px 10px;
        border-radius: 30px;
        font-size: $font-sl;
        &.failed {
          background: var(--red-cocoa);
          color: var(--cosmos-mauvelous);
        }
        &.successful {
          color: var(--japenese-sea);
          background: var(--snowy-celtic);
        }
        &.continual {
          color: var(--orient-jordy);
          background: var(--onahau-rhino);
        }
        &.onetime {
          color: var(--blue-periwinkle);
          background: var(--periwinkle-cloud);
        }
      }
    }

    .link {
      width: 90px;
      max-width: 90px;
      cursor: pointer;
      justify-content: center;
      p {
        font-family: $sansmed;
        color: var(--orient-caribbean);
        text-align: center;
      }
    }
  }
  .table_header {
    .row_member {
      color: var(--dove-gray);
      font-size: $font-sl;
      text-transform: uppercase;
    }
    .amt {
      background: var(--bg-color);
    }
  }

  .table_row {
    &:nth-child(odd) {
      .amt {
        background: var(--bg-color);
      }
    }
    &:nth-child(even) {
      background: var(--alabaster);
      .amt {
        background: var(--alabaster);
      }
    }
  }

  .filtered_list-empty {
    flex: 1;
    gap: 24px;
    top: 70px;
    left: 0px;
    width: 100%;
    padding: 50px;
    position: sticky;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid var(--alabaster);
    svg {
      width: 90px;
      height: 90px;
    }
    p {
      font-size: 18px;
      text-align: center;
      color: var(--dove-gray);
    }
  }
}
