.home_container {
  flex: 1;
  width: 100%;
  align-items: center;
  position: relative;
  flex-direction: column;
  overflow: hidden scroll;

  .page_content {
    flex: 1;
    width: 100%;
    height: max-content;
    @include padHor;
    overflow: scroll;
    flex-direction: column;
  }
}

.account_stats {
  width: 100%;
  height: 470px;
  padding: 20px 0px;
  border-radius: 5px;
  overflow: hidden;
  flex-direction: column;
  border: 1px solid var(--alto);
  position: relative;
  background: var(--white-alabaster);

  @include tablet {
    border: none;
    padding: 18px;
  }

  @include phone {
    height: 370px;
  }

  .stats_filters {
    width: 100%;
    padding: 0px 20px;
    margin-bottom: 26px;
    flex-direction: row;
    justify-content: space-between;
    .asset_revenue,
    .timeframe {
      gap: 6px;
      padding: 10px 5px;
      cursor: pointer;
      color: var(--dove-gray);
      align-items: center;
      flex-direction: row;
      height: max-content;
    }
    .asset_revenue {
      p {
        font-family: $sansmed;
      }
    }
  }
  .chart_container {
    width: 100%;
    height: calc(100% - 50px);
  }
}
