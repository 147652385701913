body {
  height: 100vh;
  padding: 0px;
  min-height: 100vh;
  background: var(--bg-color);
  background: var(--bg-color);
  // transition: background-color 0.2s ease-in;
}

#root {
  min-height: 100vh;
}

#pera-wallet-connect-modal-wrapper .pera-wallet-connect-modal {
  z-index: 9999 * 9;
}

.container {
  width: 100vw;
  display: flex;
  color: var(--timber-altra);
  font-family: $sans;
  position: relative;
  flex-direction: column;

  * {
    font-family: $sans;
    line-height: 1.5;
    font-size: $font-sm;
    overscroll-behavior: none;
  }
}
